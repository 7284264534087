* {
  margin: 0;
  padding: 0;
}

body {
  color: black;
  font-family: LatoRegular;
  font-size: 1.2rem;
  line-height: 1.5;
}

div.main p {
  max-width: 80ch;
  margin: 0.2rem 0;
}

.sidebar-container {
  height: 100%;
  width: 38%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 3.6rem;
  text-align: right;
  border-right-style: solid;
  border-width: thick;
}

.main {
  margin-left: 38%;
  padding: 3.6rem 2.4rem;
  background-color: white;
}

.logo {
  max-width: 16%;
  padding: 1.2rem 2.4rem;
}

h1 {
  font-size: 3.2rem;
  padding: 0.8rem 2.4rem;
  line-height: 1;
}

h2 {
  font-family: LatoBold;
  font-size: 1.4rem;
  line-height: 1;
  display: inline-block;
  background-color: #D9D9D9;
  padding: 0.6rem;
  margin-top: 3.6rem;
  margin-bottom: 1.8rem;
}

.h2-about {
  font-family: LatoBold;
  font-size: 1.4rem;
  line-height: 1;
  display: inline-block;
  background-color: #D9D9D9;
  padding: 0.6rem;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
}

h3 {
  font-size: 1.4rem;
  padding: 0.4rem 0;
  line-height: 1;
}

.learner-container {
  text-align: right;
  font-size: 1.4rem;
  padding: 0.8rem 2.4rem 1.8rem 2.4rem;
}

nav {
  font-family: LatoBold;
  font-size: 1.4rem;
  list-style: none;
  padding: 1.2rem 2.4rem;
}

nav a {
  display: inline-block;
  text-decoration: none;
  color: black;
  padding: 0.6rem;
}

nav a:hover {
  background-color: #D9D9D9;
}

.social-icons-container{
  display: flex;
  flex-wrap: wrap;
  padding: 1.2rem 2.4rem;
  justify-content: right;
  align-items: center;
  gap: 0.6rem;
}

.social-links{
  flex: 1;
  max-width: 2.4rem;
  padding: 0.6rem;
}

.social-links:hover {
  background-color: #D9D9D9;
}

footer {
  font-family: LatoLight;
  font-size: 1.0rem;
  padding: 2.4rem 0;
  max-width: none;
  text-align: left;
}

.award-container {
  margin-bottom: 1.8rem;
}

.education-container {
  margin-bottom: 1.8rem;
}

.experience-container {
  margin-bottom: 1.8rem;
}

.cert-container {
  margin-bottom: 1.8rem;
}

.skill-container {
  margin-bottom: 1.8rem;
}

.work-container {
  margin-bottom: 3.6rem;
}

.tag-container {
  display: flex;
  max-width: 900px;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0.4rem 0;
  gap: 0.4rem;
}

.tag {
  display: inline;
  padding: 0.4rem;
  font-size: 1.0rem;
  border-style: solid;
  border-width: thin;
}

.exp-details {
  font-size: 1.0rem;
  border-left-style: solid;
  padding-left: 0.6rem;
  margin: 0.4rem 0;
}

.link-inside {
  font-family: LatoBold;
  font-size: 1.0rem;
  color: black;
  text-decoration: none;
}

.link-inside:hover {
  background-color: #D9D9D9;
}

.link-more-projects {
  font-family: LatoBold;
  font-size: 1.4rem;
  color: black;
  text-decoration: none;
}

.link-more-projects:hover {
  background-color: #D9D9D9;
}

.p-about {
  padding: 0.6rem 0;
}

.p-summary {
  font-size: 1.6rem;
  font-style: italic;
  max-width: 60ch !important;
  padding: 0.6rem 0;
}

.project-thumbnail {
  width: 900px;
  max-width: 100%;
  margin-top: 3.6rem;
  margin-bottom: 0.6rem;
}

#more-projects-container {
  display: none;
}

#see-less-button {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1200px) {
  .logo {
    max-width: 22%;
    padding: 1.2rem 2.4rem;
  }
}

@media screen and (max-width: 992px) {
  .sidebar-container {
    height: auto;
    width: 100%;
    overflow: hidden;
    background-color: white;
    padding-top: 0;
    text-align: left;
    border-bottom-style: solid;
    border-width: thick;
  }

  .main {
    margin-top: 4.8rem;
    margin-left: 0;
    padding: 3.6rem 1.2rem;
    overflow: hidden;
    background-color: white;
  }

  .logo-name-container {
    display: flex;
    justify-content: left;
    align-items: flex-end;
  }

  .logo-container-link {
    display: inline;
    width: 4%;
    padding: 1.2rem 0.8rem 0.6rem 1.2rem;
  }

  .logo {
    display: block;
    max-width: 100%;
    padding: 0;
  }

  h1 {
    display: inline;
    font-size: 1.8rem;
    padding: 1.2rem 0 0.6rem 0;
    line-height: 1;
  }

  h2 {
    margin-top: 6.4rem;
    margin-bottom: 1.8rem;
  }

  .learner-container {
    text-align: left;
    font-size: 1rem;
    padding: 0 1.2rem 0.6rem 1.2rem;
  }

  .mobile-nav {
    overflow: hidden;
    background-color: white;
    position: relative;
  }

  #mmenu {
    display: none;
  }

  nav {
    font-family: LatoBold;
    font-size: 1.2rem;
    list-style: none;
    padding: 0.6rem 0.6rem;
  }

  nav a {
    display: block;
    padding: 0.6rem 0.6rem;
  }

  .menu-icon {
    width: 4%;
    display: block;
    position: absolute;
    padding: 0.4rem;
    top: 1.6rem;
    right: 1.2rem;
  }

  .menu-icon:hover {
    background-color: #D9D9D9;
  }

  .social-icons-container{
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.6rem 0.6rem 0.6rem;
    justify-content: left;
    align-items: center;
    gap: 1.2rem;
  }

  .social-links{
    flex: 1;
    max-width: 1.8rem;
    padding: 0.6rem;
  }

  .h2-about {
    margin-top: 3.6rem;
  }

  .tag-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .main {
    margin-top: 3.6rem;
  }

  .logo-container-link {
    display: inline;
    width: 6%;
    padding: 1.2rem 0.8rem 0.6rem 1.2rem;
  }

  .logo {
    display: block;
    max-width: 100%;
    padding: 0;
  }

  .menu-icon {
    width: 6%;
    padding: 0.4rem;
    top: 1.2rem;
    right: 0.8rem;
  }
}

@media screen and (max-width: 524px) {
  .logo-container-link {
    width: 8%;
  }

  .menu-icon {
    width: 8%;
  }
}